<template>
	<layout-default> Loading..... </layout-default>
</template>

<script>
import { useQuery } from '@vue/apollo-composable'
import { onMounted, reactive } from '@vue/composition-api'
import Store from '@/store'
import Router from '@/router'
import storeReminder from '@/_srcv2/pages/reminder/_shared/useReminderState.js'
import GetReminderReportDataQuery from '@/_srcv2/pages/reminder/graphql/GetReminderReportDataQuery.graphql'

export default {
	name: 'GetReminderReportDataOld',
	setup() {
		const { reportOurCompany, reportReminderNumber } = storeReminder()
		// get reminder report info
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { refetch } = useQuery(
			GetReminderReportDataQuery,
			() => ({
				company_nickname: reportOurCompany.value,
				reminder_id: reportReminderNumber.value,
			}),
			options,
		)
		// ****************************************************************************************************
		// const willIGetReportData = (data) => {
		//   return new Promise((resolve, reject) => {
		//     if (data !== null || undefined) {
		//       const allReportDataForReminder = useResult(
		//         result,
		//         null,
		//         (data) => data.reminder_headers
		//       )
		//       console.log('allReportDataForReminder', allReportDataForReminder)
		//       resolve(allReportDataForReminder.value[0])
		//     } else {
		//       const reason = new Error('Data could not be fetched from database')
		//       reject(reason)
		//     }
		//   })
		// }
		// const storeReportData = (data) => {
		//   return new Promise((resolve, reject) => {
		//     if (data !== null || undefined) {
		//       Store.dispatch('setReminderReportData', data).then(() => {
		//         if (Store.getters.getReminderReportData !== undefined) {
		//           Router.push({ name: 'ReminderReport' })
		//           resolve(true)
		//         }
		//       })
		//     } else {
		//       const reason = new Error('Data can not be stored')
		//       reject(reason)
		//     }
		//   })
		// }
		// // **********************************************************************************
		// const a = () => {
		//   willIGetReportData(result).then((data) => {
		//     storeReportData(data)
		//   })
		// }
		// const onSelect = () => {
		//   refetch().then((result) => {
		//     console.log('result', result)
		//     a()
		//   })
		// }
		// ------------------------------------------------------------------------
		onMounted(() => {
			console.log('reportOurCompany', reportOurCompany.value)
			console.log('reportReminderNumber', reportReminderNumber.value)
			refetch()
				.then((result) => {
					console.log(
						'refetch result.data.reminder_headers',
						result.data.reminder_headers[0],
					)
					Store.dispatch(
						'setReminderReportData',
						result.data.reminder_headers[0],
					)
				})
				.then(() => Router.push({ name: 'ReminderReport' }))
		})
		return {
			// onSelect,
		}
	},
}
</script>

<style scoped>
.margin-top-25 {
	margin-top: 25px;
}
</style>
